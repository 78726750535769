export default {
  customProduct: {
    title: '可联系客服定制专属产品',
    confirm: '确定',
    tip: '提示'
  },
  route: {
    Login: '登录',
    Registration: '注册',
    ForgetPasswd: '忘记密码',
    Home: '首页',
    Price: '价格',
    Product: '产品',
    Commission: '推广赚佣金',
    My: '全球安全可靠的IP代理服务商',
    Error: '全球安全可靠的IP代理服务商',
    CashRegister: '收银台',
    Article: '文章详情',
    NotFound: '页面未找到',
    Unauthorized: '未授权访问',
    MyBuy: '购买服务',
    MyDynamicOrder: '动态订单',
    MyStaticOrder: '静态订单',
    MyProxyAccount: '代理账户',
    MyAccountSetting: '账户设置',
    MyDashboard: '获取代理',
    CommissionStatistics: '佣金统计',
    CommissionRecords: '佣金记录',
    RenewRecord: '续期记录',
    MyAdResource: '集成工具',
    MyBalanceRecharge: '余额&充值'
  },
  nav: {
    home: '首页',
    price: '价格',
    product: '产品',
    resourceCenter: '资源中心',
    login: '登录',
    registration: '注册',
    introductionGuide: '入门指导',
    commonProblem: '常见问题',
    adsPowerAgentConfigurationTutorial: 'AdsPower代理配置教程',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower动态代理配置教程',
    bitBrowserStaticProxyConfigurationTutorial: '比特浏览器静态代理配置教程',
    blog: '博客',
    commission: '推广赚佣金',
    promotion: '推广管理',
    commissionStatistics: '佣金统计',
    commissionRecords: '佣金记录',
    useCase: '使用案例',
    webCrawlingAndDataExtraction: '网页抓取与爬取',
    socialMediaManagement: '社交媒体管理：如何有效利用IP代理提高运营效率',
    integratedTools: '集成工具',
    fingerprintBrowser: '指纹浏览器',
    cloudPhone: '云手机'
  },
  header: {
    language: {
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      en: 'English'
    },
    switchLanguage: '切换语言'
  },
  footer: {
    contactus: '联系我们',
    email: '邮箱',
    phone: '电话',
    product: '产品',
    resourceService: '资源与服务',
    useCase: '使用案例',
    legalDisclaimer: '法律声明',
    dynamicProxy: '动态真人住宅代理',
    ipv4: '数据中心IPv4代理',
    ipv6: '数据中心IPv6代理',
    staticISP: '静态住宅代理',
    staticResidential: '静态住宅代理',
    staticDatacenter: '静态数据中心代理',
    price: '价格',
    QA: 'Q&A',
    serviceClause: '服务与条款',
    lawAndPrivacy: '法律与隐私'
  },
  global: {
    title: 'A globally secure and reliable IP proxy service provider',
    confirmLogout: '确认退出登录',
    loginAgain: '重新登录',
    cancel: '取消',
    error: '错误',
    learnMore: '了解更多',
    buyNow: '立即购买',
    confirm: '确认',
    cancelDone: '取消成功',
    edit: '编辑',
    goBack: '返回',
    copy: '复制',
    copySuccess: '复制成功',
    copyFailed: '复制失败',
    all: '全部',
    noData: '暂无数据',
    reset: '重置',
    yes: '是',
    no: '否',
    startFrom: '起',
    startDate: '开始日期',
    endDate: '结束日期',
    unlimitedShared: '无限流量（共享）',
    unlimitedDedicated: '无限流量（独享）',
    customProduct: '定制专属产品',
    contactCustomService: '可联系客服定制哦'
  },
  user: {
    nickNameBlank: '昵称不能为空！',
    passwordBlank: '密码不能为空！',
    paymentPasswordBlank: '支付密码不能为空！'
  },
  auth: {
    loginBtn: '登录',
    loginLink: '登录',
    registrationBtn: '注册',
    registrationLink: '立即注册',
    loginTitle: '账号登录',
    registrationTitle: '账号注册',
    emailPlaceHolder: '请输入邮箱',
    passwordPlaceHolder: '请输入密码',
    repasswordPlaceHolder: '请输入确认密码',
    rememberPassword: '记住密码',
    forgetPassword: '忘记密码',
    hasAccountTips: '已有账号？',
    readAgreement: '我已阅读并同意',
    userAgreement: '用户服务协议',
    captchaPlaceHolder: '请输入验证码',
    sendCaptchaLink: '获取验证码',
    sendCaptchaAgain: '{second}秒后可重发',
    forgetPasswdTitle: '重置密码',
    newpasswordPlaceHolder: '请输入新密码',
    confirm: '确认',
    sendCaptchSuccess: '验证码已发送至你的注册邮箱',
    readAgreementConfirm: '请先阅读并同意用户服务协议'
  },
  home: {
    goodsTitle: '我们的产品',
    advantageTitle: '我们的优势',
    partnerTitle: '合作伙伴',
    useCaseTitle: '使用案例'
  },
  product: {
    newProduct: '新品上线',
    unlimitedShared: '无限流量（共享）',
    unlimitedDedicated: '无限流量（独享）',
    title: '我们的产品',
    features: {
      title: '主要特点',
      dynamic: {
        name: '动态性',
        desc: 'IP地址会不断轮换，增加隐蔽性和成功率',
        title: '动态真人住宅代理'
      },
      residential: {
        name: '真实住宅IP',
        desc: '来自实际的ISP提供的IP，因而看起来像普通用户的请求'
      },
      anonymity: {
        name: '高匿名性',
        desc: '难以被检测和封锁，适合绕过地理限制和访问敏感信息'
      },
      staticResidential: {
        name: '静态住宅IP',
        desc: '来自实际的ISP提供的IP，因而看起来像普通用户的请求',
        title: '静态住宅代理'
      },
      staticDatacenter: {
        name: '静态数据中心IP',
        desc: '来自数据中心的固定IP地址',
        title: '静态数据中心代理'
      }
    },
    dynamic: {
      iconClass: '',
      title: '动态真人住宅代理',
      description: '最真实的动态住宅代理，通过高频次更换住宅IP地址来增强代理的稳定性与可信度，高安全性和隐蔽地绕过网络限制和封锁',
      advantageList: [
        {
          name: '隐匿性',
          description: '业内顶尖级别的安全隐匿性'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: '合规的真人住宅IP代理网络'
        },
        {
          name: '99.97%',
          description: '正常运营时间'
        },
        {
          name: '179个',
          description: '国家位置'
        },
        {
          name: '支持',
          description: 'HTTP(S) & SOCKS5协议'
        }
      ],
      introduction: {
        title: '什么是动态住宅IP.',
        description: '动态住宅代理是通过无数真实住宅IP地址提供的代理服务，动态住宅代理会定期更换IP地址，模仿普通家庭用户的上网行为，因而难以被目标网站检测到为代理。',
        tips: '价格低至',
        buyNow: '立即选购',
        mainTitle: '主要特点',
        mainData: [
          {
            name: '动态性',
            description: 'IP地址会不断轮换，增加隐蔽性和成功率'
          },
          {
            name: '真实住宅IP',
            description: '来自实际的ISP提供的IP，因而看起来像普通用户的请求'
          },
          {
            name: '高匿名性',
            description: '难以被检测和封锁，适合绕过地理限制和访问敏感信息'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: '独享静态ISP代理',
      description: '从全球互联网服务提供商(ISP)分配活跃IP地址，使用户能够根据需要长期使用相同的住宅IP。这种独享静态住宅代理不仅速度快且高度可靠，有效模拟真实用户的操作行为',
      advantageList: [
        {
          name: '真实用户模拟',
          description: '更难被网站识别为代理，提高隐蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被监测和封锁的风险'
        },
        {
          name: '低封锁率',
          description: '使用IP时被网站封锁的概率较低'
        },
        {
          name: '灵活性',
          description: '用户可以根据需求长期使用相同的IP'
        },
        {
          name: '更好的兼容性',
          description: '支持更多类型的网站和服务'
        }
      ],
      introduction: {
        title: '真实用户模拟',
        description: '独享ISP住宅代理使用的是实际家庭用户的IP地址，更难被网站识别为代理，从而提高隐蔽性。',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封锁率',
          },
          {
            name: '灵活性',
          },
          {
            name: '更好的兼容性',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: '静态代理',
      description: '静态IPv4是由服务提供商(ISP)分配的固定IP地址，独享ISP住宅代理利用这些静态IP，确保速度的同时降低被监测和屏蔽的风险让用户可以持续使用相同的住宅IP',
      advantageList: [
        {
          name: '稳定性',
          description: '始终使用相同的IP地址，确保连接的稳定性'
        },
        {
          name: '高匿名性',
          description: '较少被检测和封锁，适合需要隐私保护的操作'
        },
        {
          name: '快速速度',
          description: '适合数据抓取和其他高带宽需求的任务'
        },
        {
          name: '可靠性',
          description: '避免了共享代理带来的速度下降或连接问题'
        },
        {
          name: '可控性',
          description: '用户可以更好地管理和监控自己的IP使用情况'
        }
      ],
      introduction: {
        title: '静态代理',
        description: '静态IPv4是由服务提供商(ISP)分配的固定IP地址，独享ISP住宅代理利用这些静态IP，确保速度的同时降低被监测和屏蔽的风险让用户可以持续使用相同的住宅IP',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '稳定性',
            description: '始终使用相同的IP地址确保连接的稳定性'
          },
          {
            name: '高匿名性',
            description: '较少被检测和封锁，适合需要隐私保护的操作'
          },
          {
            name: '快速速度',
            description: '适合数据抓取和其他高带宽需求的任务'
          },
          {
            name: '可靠性',
            description: '避免了共享代理带来的速度下降或连接问题'
          },
          {
            name: '可控性',
            description: '用户可以更好地管理和监控自己的IP使用情况'
          }
        ]
      }
    },
    staticResidential: {
      iconClass: '',
      title: '静态真人住宅代理',
      description: '从全球互联网服务提供商(ISP)分配活跃IP地址，使用户能够根据需要长期使用相同的住宅IP。这种静态住宅代理不仅速度快且高度可靠，有效模拟真实用户的操作行为',
      advantageList: [
        {
          name: '真实用户模拟',
          description: '更难被网站识别为代理，提高隐蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被监测和封锁的风险'
        },
        {
          name: '低封锁率',
          description: '使用IP时被网站封锁的概率较低'
        },
        {
          name: '高真实性',
          description: '更难被网站识别为代理，提高隐蔽性'
        },
        {
          name: '更好的稳定性',
          description: '更难被网站识别为代理，提高隐蔽性'
        }
      ],
      introduction: {
        title: '真实用户模拟',
        description: 'ISP住宅代理使用的是实际家庭用户的IP地址，更难被网站识别为代理，从而提高隐蔽性。',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封锁率',
          },
          {
            name: '高真实性',
          },
          {
            name: '更好的稳定性',
          }
        ]
      },
      searchConditions: '搜索条件',
      isExpired: '是否过期',
      notExpired: '未过期',
      expired: '已过期',
      ipLocation: 'IP位置',
      tag: '标签',
      ipsPlaceholder: '请输入IP地址，每行一个',
      search: '搜索',
      reset: '重置',
      proxyInfo: '代理信息',
      copyProxy: '复制代理',
      copyProxy1: '复制代理1',
      copyProxy2: '复制代理2',
      selectProxyInfo: '请选择要复制的代理信息',
      id: 'ID',
      ip: 'IP地址',
      totalTraffic: '总流量(GB)',
      usedTraffic: '已用流量(GB)',
      enableStatus: '启用状态',
      disabled: '已禁用',
      enabled: '已启用',
      onlineStatus: '在线状态',
      online: '在线',
      offline: '离线',
      autoRenew: '自动续费',
      on: '开启',
      off: '关闭',
      expirationTime: '到期时间',
      createTime: '创建时间',
      refundTime: '退款时间',
      operations: '操作',
      renew: '续费',
      buyTraffic: '购买流量',
      autoRenewChanged: '自动续费状态已更改',
      autoRenewChangeFailed: '自动续费状态更改失败',
      renewSuccess: '续费成功',
      changeIP: '更换IP',
      changeIPConfirm: '确定要更换IP吗？',
      changeIPSuccess: '更换IP成功',
      changeIPFailed: '更换IP失败',
      operation: '操作',
      batchCopyProxy: '批量复制代理',
      selectedCount: '已选择 {count} 项',
      noProxySelected: '请至少选择一个代理'
    },
    staticDatacenter: {
      iconClass: '',
      title: '静态数据中心代理',
      description: 'IP地址来自数据中心的服务器，由云服务商或专业代理服务商批量分配。这类IP属于"机房IP"（Datacenter IP）',
      advantageList: [
        {
          name: '带宽高',
          description: '延迟低，适合需要快速响应的任务'
        },
        {
          name: '低成本',
          description: '价格较低，因数据中心可批量生成IP，适合大规模部署'
        }
      ],
      introduction: {
        title: '静态数据中心代理',
        description: 'IP地址来自数据中心的服务器，由云服务商或专业代理服务商批量分配。这类IP属于"机房IP"（Datacenter IP）',
        tips: '价格低至',
        buyNow: '立即选购',
        mainData: [
          {
            name: '带宽高',
          },
          {
            name: '低成本',
          }
        ]
      },
      searchConditions: '搜索条件',
      isExpired: '是否过期',
      notExpired: '未过期',
      expired: '已过期',
      ipLocation: 'IP位置',
      tag: '标签',
      ipsPlaceholder: 'IP数组(查询多个ip时,请以换行分隔,最大支持500条)',
      search: '搜索',
      reset: '重置',
      proxyInfo: '代理信息',
      copyProxy: '复制代理',
      id: 'ID',
      ip: 'IP',
      totalTraffic: '总流量/GB',
      usedTraffic: '已使用流量/GB',
      enableStatus: '启用状态',
      enabled: '启用',
      disabled: '禁用',
      onlineStatus: '在线状态',
      online: '在线',
      offline: '离线',
      autoRenew: '自动续期',
      on: '开启',
      off: '关闭',
      expirationTime: '过期时间',
      createTime: '创建时间',
      refundTime: '退款时间',
      operations: '操作',
      renew: '续期',
      buyTraffic: '购买流量',
      changeIP: '更换IP',
      changeIPConfirm: '确定要更换IP吗？',
      changeIPSuccess: '更换IP成功',
      changeIPFailed: '更换IP失败',
      operation: '操作',
      batchCopyProxy: '批量复制代理',
      selectedCount: '已选择 {count} 项',
      noProxySelected: '请至少选择一个代理'
    }
  },
  advantage: {
    stability: '稳定性',
    fastspeed: '快速速度',
    highanonymity: '高匿名性',
    reliability: '可靠性',
    controllability: '可控性'
  },
  price: {
    title: '多样化的价格',
    outOfStock: '缺货',
    comingSoon: '即将上线',
    dayPackage: '天套餐',
    dynamic: '动态真人住宅代理',
    staticResidential: '静态住宅代理',
    staticDatacenter: '静态数据中心代理',
    staticISP: '独享静态ISP代理',
    static: '静态代理',
    advantage: [
      {
        name: '安全与隐私保障'
      },
      {
        name: '300+国家 上千万代理池'
      },
      {
        name: '卓越的网络速度'
      },
      {
        name: '高度可扩展性'
      },
      {
        name: '99.9%正常运行时间'
      },
      {
        name: '智能流量管理'
      }
    ]
  },
  my: {
    unitPrice: '流量单价',
    status: '状态',
    exclusiveDiscount: '专属优惠',
    originalPriceLabel: '原价：',
    lastWeek: '最近一周',
    lastMonth: '最近一个月',
    lastThreeMonths: '最近三个月',
    initialFlow: 'IP初始流量',
    ipNum: 'IP数量',
    tag: '标签',
    ipLocation: 'IP位置',
    extraFlowPrice: '额外流量单价',
    buyProxy: '购买代理',
    dashboard: '获取代理',
    proxyManagement: '订单明细',
    proxySetting: '代理管理',
    myAccount: '我的账户',
    accountSetting: '账户设置',
    authUser: '授权用户',
    dynamic: '动态订单',
    static: '静态订单',
    staticOrder: '静态订单',
    logout: '退出登录',
    leftFlow: '剩余流量',
    expirationDate: '到期时间',
    autoRenew: '自动续期',
    autoRenewChanged: '自动续期设置已更新',
    autoRenewChangeFailed: '自动续期设置更新失败',
    country: '国家',
    stateCityOrISP: '州-城市/ISP',
    stateCity: '州-城市',
    ISP: 'ISP',
    state: '州',
    city: '城市',
    createAuthUser: '创建授权用户',
    recharge: '充值',
    buy: '购买',
    selectPlaceHolder: '请选择',
    changeMode: '轮换模式',
    randomIP: '随机IP',
    delayIP: '延时IP',
    delayDuration: 'IP持续时长（1-120分钟）',
    hostAndPort: '主机IP:端口',
    proxyUsername: '代理用户名',
    proxyPassword: '代理密码',
    tips: '由于政策原因，本站代理服务不支持在中国大陆使用。',
    proxyAddress1: '用户名：密码：主机IP:端口',
    proxyAddress2: '主机IP:端口：用户名：密码',
    proxyAddress3: '主机IP:端口：用户名：密码',
    addBatch: '批量生成',
    copyList: '复制代理清单',
    userPlaceHolder: '输入用户名',
    search: '查询',
    addAuthUser: '添加授权用户',
    username: '用户名',
    flowLimit: '流量使用上限',
    totalConsume: '总计消耗',
    createTime: '创建时间',
    password: '密码',
    usernamePlaceHolder: '支持6～24位字符，字母/数字/下划线',
    usernameEmpty: '请输入用户名',
    usernameError: '请输入6-24位字符的用户名',
    passwordPlaceHolder: '密码需要6～15字符，不支持特殊符号',
    passwordEmpty: '请输入密码',
    passwordError: '密码需要6-15字符，不支持特殊符号',
    flowLimitGB: '流量上限（GB）',
    orderDetail: '订单明细',
    startAt: '开始日期',
    endAt: '结束日期',
    orderNoPlaceHolder: '输入订单号',
    orderNo: '订单号',
    packageDuration: '套餐期限',
    flow: '流量/GB',
    totalAmount: '总金额',
    orderStatus: '订单状态',
    paymentTime: '支付时间',
    actions: '操作',
    toPay: '去支付',
    proxyType: '代理类型',
    orderType: '订单类型',
    selectPackage: '选择套餐',
    renewTable: {
      ip: 'IP',
      autoRenew: '自动续期',
      proxyType: '代理类型',
      totalCost: '总花费',
      renewDuration: '续期时长',
      flow: '流量',
      renewStatus: '续期状态',
      renewResult: '续期结果',
      renewTime: '续期时间'
    },
    staticOrderTable: {
      orderNo: '订单号',
      packageDuration: '套餐期限',
      initialFlow: 'IP初始流量',
      ipNum: 'IP数量',
      tag: '标签',
      ipLocation: 'IP位置',
      actions: '操作'
    },
    purchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>购买须知</strong></span></p><p>1、使用代理前，您需要<span style="color: rgb(225, 60, 57);"><strong>自备境外网络</strong></span>，大陆内地网络无法直接使用该代理。</p><p>2、代理产品售出后<span style="color: rgb(225, 60, 57);"><strong>不支持退换</strong></span>，购买前请确认代理类型及地区等信息避免误购。 </p><p>3、代理产品使用请注意必须<span style="color: rgb(225, 60, 57);"><strong>合法合规</strong></span>，否则后果自负。</p>',
    staticPurchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>购买须知</strong></span></p><p>1、使用代理前，您需要<span style="color: rgb(225, 60, 57);"><strong>自备境外网络</strong></span>，大陆内地网络无法直接使用该代理。</p><p>2、代理产品售出后<span style="color: rgb(225, 60, 57);"><strong>不支持退换</strong></span>，购买前请确认代理类型及地区等信息避免误购。</p><p>3、代理产品使用请注意必须<span style="color: rgb(225, 60, 57);"><strong>合法合规</strong></span>，否则后果自负。</p><p>4、静态IP代理为固定期限套餐，<span style="color: rgb(225, 60, 57);"><strong>过期后无法找回</strong></span>，请注意续期时间！</p>',
    day: '天',
    payableAmount: '应付金额',
    payType: '支付方式',
    alipay: '支付宝',
    alipayPay: '支付宝支付',
    payAgreement: '支付即视为同意',
    agreement: '《服务协议》',
    agreementError: '请阅读并同意支付服务协议！',
    pendingPay: '待支付',
    finishPay: '已支付',
    cancelPay: '已关闭',
    avatar: '头像',
    email: '邮箱',
    nick: '昵称',
    loginPassword: '登录密码',
    paymentPassword: '支付密码',
    paymentPasswordTip: '支付密码必须是6位数字',
    paymentPasswordUpdateSuccess: '支付密码修改成功',
    invitationCode: '邀请码',
    balanceAndRecharge: '余额&充值',
    currentBalance: '当前余额',
    rechargeAmount: '充值金额',
    rechargeAmountPlaceholder: '充值金额至少为1元',
    rechargeHistory: '充值记录',
    rechargeSuccess: '充值成功',
    rechargePending: '充值中',
    yuan: '元',
    balanceRecharge: '余额充值',
    ipLocationRequired: 'IP位置为必选项',
    renewRecordError: '获取续费记录失败',
    renewSuccess: '续期成功',
    renewFail0: '续期失败[系统繁忙]',
    renewFail1: '续期失败[用户账户余额不足]',
    renewFail2: '续期失败[用户被禁用]',
    autoRenewOption: '自动续期',
    autoRenewTip: '请保证余额充足，确保自动续期成功',
    renew: '续费',
    buyTraffic: '购买流量',
    buyTrafficSuccess: '购买流量成功',
    totalCost: '总费用',
    renewDuration: '续费时长',
    extraPricePerG: '每GB额外费用',
    buyTrafficAmount: '购买流量数量',
    insufficientBalance: '余额不足',
    goToRecharge: '去充值',
    purchaseSuccess: '购买成功',
    purchaseFailed: '购买失败',
    getUserInfoFailed: '获取用户信息失败',
    renewRecordTitle: '续期记录'
  },
  payment: {
    pleaseSetPaymentPassword: '请先设置支付密码',
    goToSetPassword: '去设置支付密码',
    passwordSetSuccess: '支付密码设置成功',
    passwordLength: '支付密码必须为6位数字',
    enterPaymentPassword: '请输入支付密码',
    confirmPaymentPassword: '请确认支付密码',
    setPaymentPassword: '设置支付密码',
    orderInfo: '订单信息',
    orderNo: '订单编号',
    amount: '支付金额',
    orderStatus: '订单状态',
    exchangeAmount: '人民币金额',
    pendingPay: '待支付',
    paySuccess: '支付成功',
    cancelPay: '已关闭',
    timeout: '超时提示',
    orderExpired: '订单已过期',
    error: '错误提示',
    qrcodeError: '二维码生成失败',
    networkError: '网络错误',
    fetchOrderError: '获取订单信息失败',
    orderNotFound: '订单不存在',
    createQrcodeError: '创建支付二维码失败',
    payUrlError: '支付链接获取失败',
    payError: '支付失败',
    pleasePayIn: '请在{time}内完成支付',
    scanToPay: '请使用手机扫码支付',
    zhifubaopay: '支付宝支付',
    weixinpay: '微信支付',
    alipay: '支付宝',
    weixin: '微信',
    balance: '余额支付',
    balancePay: '余额支付',
    currentBalance: '当前余额',
    insufficientBalance: '余额不足',
    goToRecharge: '去充值',
    noPaymentPassword: '您尚未设置支付密码',
    setPaymentPassword: '设置支付密码',
    enterPaymentPassword: '请输入6位数字',
    confirmPaymentPassword: '请再次输入6位数字',
    passwordMismatch: '两次输入的密码不一致',
    passwordSetSuccess: '支付密码设置成功',
    passwordSetFailed: '支付密码设置失败',
    passwordLength: '支付密码必须为6位数字',
    pleaseInputPaymentPassword: '请输入6位数字',
    confirmPay: '确认支付',
    paySuccessTips: '支付成功，正在跳转...',
    zhifubaotips: '打开支付宝 [扫一扫]\n扫描二维码完成支付',
    weixintips: '打开微信 [扫一扫]\n扫描二维码完成支付',
    passwordWeak: '弱',
    passwordMedium: '中等',
    passwordGood: '良好',
    passwordStrong: '强'
  },
  test: '测试',
  commission: {
    banner: {
      title: '加入',
      subtitle: '成为我们的合作伙伴，最高可获得{percent}的佣金',
      button: '立即开始'
    },
    steps: {
      title: '如何开始',
      step1: '注册账号',
      step2: '分享推广链接',
      step3: '客户完成购买',
      step4: '获得佣金'
    },
    tips: {
      title: '推广贴士',
      tipPrefix: '贴士',
      tip1: {
        title: '选择合适的推广渠道',
        content: '根据目标受众选择最适合的社交媒体平台或网站进行推广'
      },
      tip2: {
        title: '制作优质的推广内容',
        content: '创作有价值的内容，突出产品优势和实际应用场景'
      },
      tip3: {
        title: '保持与潜在客户互动',
        content: '及时回复咨询，提供专业的产品建议和支持'
      },
      tip4: {
        title: '追踪推广效果',
        content: '定期分析数据，优化推广策略，提高转化率'
      }
    },
    statistics: {
      title: '我的推广链接',
      copyButton: '复制链接',
      copySuccess: '推广链接已复制到剪贴板',
      copyFailed: '复制失败，请手动复制',
      promotionLink: '推广链接',
      pendingCommission: '待结算佣金',
      suspendedCommission: '已挂起佣金',
      finishedCommission: '已结算佣金',
      doingCommission: '处理中佣金',
      exchangeAmount: '折合人民币：'
    },
    settlement: {
      title: '结算账单',
      columns: {
        id: 'ID',
        settleBatchNo: '结算批次号',
        amount: '金额',
        exchangeAmount: '折合人民币',
        handleStatus: '处理状态',
        handleTime: '处理时间',
        createTime: '创建时间',
        updateTime: '更新时间'
      },
      status: {
        pending: '待结算',
        suspended: '已挂起',
        settled: '已结算',
        closed: '已关闭',
        doing: '处理中'
      },
      search: {
        placeholder: {
          settleBatchNo: '请输入结算批次号',
          handleStatus: '请选择处理状态'
        },
        dateRange: '时间范围',
        dateTo: '至',
        startDate: '开始日期',
        endDate: '结束日期',
        submit: '查询',
        reset: '重置'
      },
      submit: {
        button: '提交结算',
        title: '提交结算申请',
        payeeName: '收款人姓名',
        payeeNamePlaceholder: '请输入收款人姓名',
        payeeNameRequired: '请输入收款人姓名',
        alipayAccount: '支付宝账号',
        alipayAccountPlaceholder: '请输入支付宝账号',
        alipayAccountRequired: '请输入支付宝账号',
        amount: '可结算金额',
        cancel: '取消',
        confirm: '提交',
        success: '提交结算申请成功',
        error: '提交结算申请失败',
        tooltip: '一般需要一个工作日左右处理结算申请，请耐心等待！'
      },
      pagination: {
        total: '共 {total} 条',
        pageSize: '每页 {size} 条'
      },
      loading: '加载中...',
      error: '获取结算列表失败'
    },
    orderNo: '订单编号',
    settleBatchNo: '结算批次号',
    datePicker: {
      startDate: '开始日期',
      to: '至',
      endDate: '结束日期'
    },
    settleStatus: '结算状态',
    statusOptions: {
      pending: '待结算',
      suspended: '已挂起',
      settled: '已结算',
      processing: '结算处理中'
    },
    search: '搜索',
    reset: '重置',
    amount: '佣金金额',
    exchangeAmount: '结算金额',
    settleTime: '结算时间',
    suspendedTime: '暂停时间',
    createTime: '创建时间'
  },
  validation: {
    required: '此项为必填项',
    min: '不能小于{min}'
  },
  common: {
    warning: '警告',
    cancel: '取消'
  },
  pagination: {
    total: '共 {total} 条记录',
    pageSize: '每页 {size} 条',
    goto: '前往',
    page: '页',
    prev: '上一页',
    next: '下一页',
    pageClassifier: '页',
    sizes: '条/页'
  },
  resource: {
    officialDisclaimer: '官方仅展示，如在使用过程中存在使用问题请咨询平台方客服'
  },
  article: {
    loadFailed: '加载失败',
    articleDetail: '文章详情',
    lastUpdate: '最后更新：',
    articleNotFound: '文章未找到',
    loadError: '加载文章失败，请稍后重试',
    noArticleId: '文章ID不存在'
  },
  seo: {
    home: {
      title: 'IP-INSTAL - 专业IP代理服务提供商',
      description: 'IP-INSTAL提供全球静态IP代理、TG防封、海外网络环境搭建、便宜的住宅代理、爬虫数据采集IP等服务',
      keywords: '全球静态IP代理,TG防封,海外网络环境设置,便宜的住宅代理,爬虫数据采集IP,社媒多账号,跨境电商,真实住宅IP代理,动态IP和静态IP的区别,IP代理哪家靠谱,TikTok批量养号IP代理,亚马逊防关联静态IP'
    },
    registration: {
      title: '注册账户 - IP-INSTAL 专业IP代理服务',
      description: '注册 IP-INSTAL 账户，获取高质量的全球静态IP代理、TG防封IP、住宅代理等服务，支持多种应用场景',
      keywords: '高匿名名IP代理,跨境电商稳定ip,便宜的代理ip,免费代理IP'
    }
  },
  structuredData: {
    organization: {
      name: 'IP-INSTAL',
      description: 'IP-INSTAL提供高质量的静态住宅代理、静态数据中心代理和动态代理服务，支持全球多个地区，稳定可靠。',
      contactType: '客户服务'
    }
  }
}
